<template>
    <div
        dir="ltr"
        class="ml-auto flex min-w-full items-center border-y border-neutral-200  bg-white px-6 sm:border-r sm:py-0 lg:h-20"
    >
        <div class="-mx-6 flex shrink-0 items-end  p-6 sm:mx-0 sm:border-none sm:p-6 sm:px-0">
            <NuxtLinkLocale
                to="/"
                class="flex items-center gap-2"
            >
                <img
                    class="h-8 w-auto lg:hidden"
                    src="../assets/images/nLogo.png"
                    alt="N-Issues"
                ><span class="font-medium lg:hidden">Issues</span>
            </NuxtLinkLocale>
        </div>
        <div class="ml-auto flex items-center gap-6">
            <div class="flex h-12 items-center">
                <span>
                    <NfsButton
                        v-if="!isAuthenticated"
                        class="uppercase"
                        @click="handleLogin"
                    >
                        {{ $t('BUTTON.ANY',{text: 'login'}) }}
                    </NfsButton>
                    <div
                        v-else
                        class="relative top-1"
                    >
                        <UserWidget
                            :name="user?.name"
                            :email="user?.email"
                            :picture="user?.avatar"
                        />
                    </div>
                </span>
            </div>
            <div :class="['border-l border-neutral-300 py-6 pl-6 transition-opacity sm:hidden', props.showIcon?'opacity-100' : 'opacity-0']">
                <NfsIcon
                    icon="hamberger-menu"
                    :cursor="true"
                    size="small"
                    @action="openSidebarMenu"
                />
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { jwtDecode } from 'jwt-decode';
import { useRegisterSubscriber } from '../composables/pushNotifications';
import { useIsAuthenticated } from '~/composables/isAuthenticated';
const props = defineProps({
    showIcon: {
        type: Boolean,
        default: true
    }
});
const emit = defineEmits<{
  (e: 'action'): void
}>();
const user = useUser().value;
const token = localStorage.getItem('authToken');
if (token) {
    const decodedToken = jwtDecode(token);
    user.name = decodedToken?.name;
    user.email = decodedToken?.email;
    user.sub = decodedToken?.sub;
    user.role_id = decodedToken.role_id;
    user.permissions = decodedToken.permissions;
    useRegisterSubscriber();
}

const handleLogin = () => {
    navigateTo('/login');
};
const isAuthenticated = useIsAuthenticated();

function openSidebarMenu() {
    emit('action');
}

</script>
